import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonBase, Typography, Dialog, DialogTitle } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    image: {
        borderRadius: 15,

        '&:hover': {
            '&  $imageFilter': {
                opacity: 0.7
            },

            '& $buttonLabel': {
                opacity: 1,
                transform: 'translateY(0)'
            },

            '& $readMore': {
                opacity: 1,
                transform: 'translateY(0)'
            }
        }
    },

    bgImage: {
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
        borderRadius: 15
    },

    imageFilter: {
        borderRadius: 15,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.primary.main,
        opacity: 0,
        transition: theme.transitions.create('opacity'),
    },

    buttonLabel: {
        position: 'absolute',
        zIndex: 3,
        color: 'white',
        opacity: 0,
        transform: 'translateY(-100%)',
        transition: theme.transitions.create(['opacity', 'transform'])
    },

    readMore: {
        position: 'absolute',
        bottom: '2rem',
        zIndex: 3,
        color: 'white',
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
        opacity: 0,
        transform: 'translateY(2rem)',
        transition: theme.transitions.create(['opacity', 'transform'])
    },

    modalImage: {
        width: '100%'
    },

    modalTitle: {
        backgroundColor: theme.palette.common.gray,
        textAlign: 'center',
        borderBottom: `3px solid ${theme.palette.secondary.main}`
    }
}))

const ImageButton = ({ label, bgimage }) => {
    const [open, setOpen] = React.useState(false)
    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <ButtonBase
                className={classes.image}
                focusRipple
                onClick={handleClickOpen}
            >
                <img className={classes.bgImage} src={bgimage} />
                <div className={classes.imageFilter} />
                <Typography className={classes.buttonLabel} color="text.primary" variant="h2" align="center">
                    {label}
                </Typography>
                <Typography className={classes.readMore} align="center">
                    Read More
            </Typography>
            </ButtonBase>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='md'
            >
                <DialogTitle className={classes.modalTitle} disableTypography><Typography variant="h2">{label}</Typography></DialogTitle>
                <img className={classes.modalImage} src={bgimage} />
            </Dialog>
        </>
    )
}

export default ImageButton
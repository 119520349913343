import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const PageSection = ({ children, id, bgImage, bgcolor, paddingExtra }) => {
    const useStyles = makeStyles({
        sectionContainer: {
            backgroundImage: bgImage ? `url(${bgImage})` : 'none',
            backgroundSize: 'cover',
            // backgroundColor: offWhite ? "#D2DFE833" : 'white'
        }
    })

    const classes = useStyles()

    return (
        <Box id={id} component="section" py={paddingExtra ? 30 : 10} className={classes.sectionContainer} bgcolor={bgcolor}>
            {children}
        </Box>
    )
}

export default PageSection
import React, { Children } from 'react'
import Carousel from 'react-material-ui-carousel'
import ImageButton from 'components/ImageButton'
import { Grid } from "@material-ui/core"
import { useTheme, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    indicator: {
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },

    activeIndicator: {
        color: theme.palette.secondary.main,

        '&:hover': {
            color: theme.palette.secondary.main
        }
    },

    grid: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },

    carousel: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}))

const ImageSlider = ({ children }) => {
    const classes = useStyles()

    return (
        <>
            <Grid className={classes.grid} container spacing={5}>
                {
                    Children.map(children, child => (

                        <Grid item md={4}>
                            {child}
                        </Grid>
                    ))
                }
            </Grid>
            <Carousel
                className={classes.carousel}
                animation="slide"
                autoPlay={false}
                navButtonsAlwaysVisible
                indicatorProps={{
                    className: classes.indicator
                }}
                activeIndicatorProps={{
                    className: classes.activeIndicator
                }}
            >
                {
                    Children.map(children, child => (
                        child
                    ))
                }
            </Carousel>
        </>
    )
}

export default ImageSlider
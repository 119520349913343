export default {
    MuiButton: {
        root: {
            textTransform: 'none',
            paddingRight: '2rem',
            paddingLeft: '2rem'
        },

        sizeLarge: {
            padding: '1rem 5rem'
        }
    },

    MuiFilledInput: {
        root: {
            backgroundColor: 'rgba(255, 255, 255, 0.45)',
            color: "rgb(73, 76, 98)",
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)'
        },
    },

    MuiButtonGroup: {
        groupedTextHorizontal: {
            "&:not(:last-child)": {
                borderRight: 0,
                marginRight: '2rem'
            }
        },

        groupedTextVertical: {
            "&:not(:last-child)": {
                borderBottom: 0
            }
        }
    },

    MuiTab: {
        root: {
            padding: '5px',
            opacity: 1
        }
    },

    MuiDialog: {
        container: {
            backgroundColor: 'rgba(73, 76, 98, 0.8)'
        }
    }
}
import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from 'theme'
import { Helmet } from 'react-helmet'
import SiteHeader from 'components/SiteHeader'
import SiteFooter from 'components/SiteFooter'
import styles from 'src/styles.css'

const PageLayout = ({ children }) => {

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>J3 Capital</title>
                <link rel="stylesheet" href="https://use.typekit.net/few7rge.css" />
            </Helmet>
            <SiteHeader />
            {children}
            <SiteFooter />
        </ThemeProvider>
    )
}

export default PageLayout
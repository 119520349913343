import React from "react"
import { Link } from "gatsby"
import { Typography, Container, Box, TextField, Grid, Button, Paper, Card, CardContent } from '@material-ui/core'

import PageLayout from "components/PageLayout"
import PageSection from 'components/PageSection'
import ImageButton from 'components/ImageButton'
import ImageSlider from 'components/ImageSlider'
import CardSlider from 'components/CardSlider'
import FeatureCard from 'components/FeatureCard'

const IndexPage = () => (
  <PageLayout>
    <PageSection bgImage={require('images/banner.png')} paddingExtra>
      <Container>
        <Box color="text.primary" textAlign="center">
          <Typography variant="h1">Leading the Industry</Typography>
          <Typography variant="h2">Since 1996</Typography>
        </Box>
      </Container>
    </PageSection>
    <PageSection id="projects" bgcolor="common.gray">
      <Container>
        <Box clone color="text.primary" textAlign="center" pb={5}>
          <Container maxWidth="md">
            <Typography variant="h3" color="secondary">Projects</Typography>
            <Box clone py={2}>
              <Typography variant="h2">Our Communities</Typography>
            </Box>
            <Typography>We specialize in the redevelopment of financially troubled and physically distressed assets in unique underlying cities throughout CA, NV, AZ</Typography>
          </Container>
        </Box>
        <ImageSlider>
          <ImageButton
            bgimage={require('images/house/el-amor-northpark.png')}
            label="El Amore"
          />

          <ImageButton
            bgimage={require('images/house/fremont-hills-fremont.png')}
            label="Fremont Hills"
          />

          <ImageButton
            bgimage={require('images/house/hero-breeze-hill.png')}
            label="Breeze Hill"
          />

          <ImageButton
            bgimage={require('images/house/maddox-mesa-az.png')}
            label="Maddox"
          />

          <ImageButton
            bgimage={require('images/house/solana-mar_solana-beach.png')}
            label="Solana Mar"
          />

          <ImageButton
            bgimage={require('images/house/the_montera_la-mesa.png')}
            label="The Montera"
          />
        </ImageSlider>
      </Container>
    </PageSection>

    <PageSection id="firm" bgcolor="#373A4F">
      <Container>
        <Box clone color="text.primary" textAlign="center" pb={5}>
          <Container maxWidth="md">
            <Typography variant="h3" color="secondary">Firm</Typography>
            <Box clone py={2}>
              <Typography variant="h2">About Us</Typography>
            </Box>
            <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dui dolor, tristique vitae dignissim sed, ultrices eget felis. Suspendisse purus massa, tempus non arcu sit amet,</Typography>
          </Container>
        </Box>
      </Container>
      {/* <img style={{ width: "100%" }} src={require('images/meeting.png')} /> */}

      <Container>
        <CardSlider>
          <FeatureCard
            icon={require('images/icons/award.svg')}
            label={'PRI - Principals for Resposible Investing'}
          />
          <FeatureCard
            icon={require('images/icons/construction.svg')}
            label={'Network of Developers'}
          />
          <FeatureCard
            icon={require('images/icons/clipboard.svg')}
            label={'Property Management Team'}
          />
          <FeatureCard
            icon={require('images/icons/bank.svg')}
            label={'California Bank & Trust Partner'}
          />
          <FeatureCard
            icon={require('images/icons/bbb.svg')}
            label={'BBB A+ Rating'}
          />
        </CardSlider>
        <Box textAlign="center" pt={5}>
          <Button size="large" variant="contained" color="primary" component={Link} to="#contact">Contact Us</Button>
        </Box>
      </Container>
    </PageSection>

    <PageSection id="specialty" bgImage={require('images/adaptive-reuse-image.png')} paddingExtra>
      <Container maxWidth="md">
        <Box color="text.primary" textAlign="center">
          <Typography variant="h3" color="secondary">Our Specialties</Typography>
          <Box clone py={2}>
            <Typography variant="h2">Adaptive Reuse</Typography>
          </Box>
          <Typography>Adaptive reuse is the practice of re-purposing a building with one use and converting to another. ... Converting a commercial building into a residential use is relatively common. There will be zoning and building code issues to investigate.</Typography>
        </Box>
      </Container>
    </PageSection>
    <PageSection id="contact" bgImage={require('images/contact-us-image.png')}>
      <Container maxWidth="md">
        <Box color="text.secondary" textAlign="center">
          <Box pb={5}>
            <Box clone pb={5}>
              <img src={require('images/j3-logo-colored.png')} />
            </Box>
            <Typography variant="h2" color="primary">Contact Us</Typography>
            <Box clone py={2}>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque dui dolor, tristique vitae dignissim sed, ultrices eget felis. Suspendisse purus massa, tempus non arcu sit amet,</Typography>
            </Box>
          </Box>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  fullWidth
                  margin="normal"
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  fullWidth
                  margin="normal"
                  variant="filled"
                />

              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  type="email"
                  fullWidth
                  margin="normal"
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone Number"
                  type="tel"
                  fullWidth
                  margin="normal"
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Comments"
                  fullWidth
                  margin="normal"
                  variant="filled"
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
            <Box textAlign="center" pt={5}>
              <Button size="large" variant="contained" color="primary">Submit</Button>
            </Box>
          </form>
        </Box>
      </Container>
    </PageSection>
  </PageLayout>
)

export default IndexPage

import React from 'react'
import { Box, Container, Typography, Button, ButtonGroup, IconButton, Grid, List, ListItem, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: theme.palette.common.gray,
        color: 'white'
    },

    copyright: {
        backgroundColor: "#404359",
        color: 'white'
    },

    list: {
        color: 'white',

        '& *': {
            padding: 0
        }
    },

    listHeader: {

        '& *': {
            fontWeight: 700,
            marginBottom: '.75rem'
        }
    }
}))

const SiteFooter = () => {
    const classes = useStyles()

    return (
        <>
            <Box className={classes.footer} component="section" py={5}>
                <Container>
                    <Grid container justify="space-between" alignItems="start">
                        <Grid item xs={12} md={6}>
                            <Box color="common.white">
                                <img src={require("images/j3-logo-white.png")} />
                                <Box clone py={3}>
                                    <Typography>90 Fifth Avenue, 3rd Floor<br />San Diego, CA 1980<br />(123) 456-7890</Typography>
                                </Box>
                                <Box pb={3}>
                                    <IconButton aria-label="facebook">
                                        <FacebookIcon htmlColor="#ffffff" />
                                    </IconButton>
                                    <IconButton aria-label="twitter">
                                        <TwitterIcon htmlColor="#ffffff" />
                                    </IconButton>
                                    <IconButton aria-label="youtube">
                                        <YouTubeIcon htmlColor="#ffffff" />
                                    </IconButton>
                                    <IconButton aria-label="instagram">
                                        <InstagramIcon htmlColor="#ffffff" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <List
                                className={classes.list}
                            >
                                <ListItem>
                                    <ListItemText className={classes.listHeader} primary="Company" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Agents</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Property Search</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Blog</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Contact Us</ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <List
                                className={classes.list}
                            >
                                <ListItem>
                                    <ListItemText className={classes.listHeader} primary="Services" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Buy Properties</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Sell Properties</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Rent Properties</ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <List
                                className={classes.list}
                            >
                                <ListItem>
                                    <ListItemText className={classes.listHeader} primary="Explore" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Homes for Rent</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Apartments for Rent</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Homes for Sale</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText>Apartments for Slae</ListItemText>
                                </ListItem>
                            </List>
                        </Grid>

                    </Grid>
                </Container>
            </Box>
            <Box className={classes.copyright} component="section" py={10}>
                <Container>
                    <Box clone pb={3}>
                        <Typography variant="subtitle1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                    </Box>
                    <Typography variant="subtitle1">Copyright © 2020 J3 Capital Group. All Rights Reserved.</Typography>
                </Container>
            </Box>
        </>

    )
}

export default SiteFooter
import React from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: theme.palette.common.gray,
        width: 290,
        height: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15
    },

    cardContent: {
        textAlign: 'center'
    },

    icon: {
        marginBottom: '1rem'
    }
}))

const FeatureCard = ({ icon, label }) => {
    const classes = useStyles()

    return (
        <Card className={classes.card} raised>
            <CardContent className={classes.cardContent}>
                <img className={classes.icon} src={icon} />
                <Typography variant="h4">{label}</Typography>
            </CardContent>
        </Card>
    )
}

export default FeatureCard
export default {
    fontFamily: "obvia, sans-serif",

    h1: {
        fontSize: '3.25rem',
        fontWeight: '700'
    },

    h2: {
        fontSize: '2.25rem',
        fontWeight: '400'
    },

    h3: {
        fontSize: '1.125rem',
        fontWeight: '400',
        textTransform: 'uppercase'
    },

    h4: {
        fontSize: '1.25rem',
        fontWeight: '400'
    },

    h5: {
        fontSize: '1.125rem',
        fontWeight: '500'
    },

    subtitle1: {
        fontSize: '0.75rem',
        fontWeight: '300'
    },

    subtitle2: {
        fontSize: '1.75rem',
        fontWeight: '300'
    },

    body1: {
        fontSize: '1.125rem',
        fontWeight: '300'
    },

    button: {
        fontSize: '1.125rem',
        fontWeight: 400
    }
}
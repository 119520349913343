import React, { Children } from 'react'
import Carousel from 'react-material-ui-carousel'
import ImageButton from 'components/ImageButton'
import { Grid, Container, Box } from "@material-ui/core"
import { useTheme, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    indicator: {
        color: theme.palette.common.gray,

        '&:hover': {
            color: theme.palette.secondary.main
        }
    },

    activeIndicator: {
        color: theme.palette.secondary.main,

        '&:hover': {
            color: theme.palette.secondary.main
        }
    },

    indicatorContainer: {
        marginTop: '1rem'
    },

    grid: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },

    carousel: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },

    card: {
        display: 'flex',
        justifyContent: 'center',
    }
}))

const CardSlider = ({ children }) => {
    const classes = useStyles()

    return (
        <>
            <Grid className={classes.grid} justify="center" container spacing={5}>
                {
                    Children.map(children, child => (

                        <Grid item>
                            {child}
                        </Grid>
                    ))
                }
            </Grid>
            <Carousel
                className={classes.carousel}
                animation="slide"
                navButtonsAlwaysVisible
                autoPlay={false}
                indicatorProps={{
                    className: classes.indicator
                }}
                activeIndicatorProps={{
                    className: classes.activeIndicator
                }}
                indicatorContainerProps={{
                    className: classes.indicatorContainer
                }}
            >
                {
                    Children.map(children, child => (
                        <Box className={classes.card}>
                            {child}
                        </Box>
                    ))
                }

            </Carousel>
        </>
    )
}

export default CardSlider
export default {
    text: {
        primary: '#fff',
        secondary: '#494C62'
    },

    primary: {
        main: '#645FAA',
        contrastText: '#F5F8FA'
    },

    secondary: {
        main: '#E3C146',
        contrastText: '#F5F8FA'
    },

    common: {
        white: '#FFFFFF',
        gray: '#494C62'
    }
}
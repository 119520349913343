import React, { useState } from 'react'
import { navigate, Link } from 'gatsby'
import { Container, Grid, Button, Drawer, SwipeableDrawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Box, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles(theme => ({
    menuIcon: {
        color: "#fff"
    },

    drawer: {
        backgroundColor: theme.palette.common.gray
    }
}))

const NavMenu = ({ children, display }) => {
    const [showDrawer, setShowDrawer] = useState(false)

    const toggleDrawer = open => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setShowDrawer(open)
    }

    const classes = useStyles()

    return (
        <>
            <Box clone display={display}>
                <IconButton size="medium" onClick={toggleDrawer(true)}>
                    <MenuIcon className={classes.menuIcon} fontSize="large" />
                </IconButton>
            </Box>
            <Drawer PaperProps={{ className: classes.drawer }} anchor="right" open={showDrawer} onClose={toggleDrawer(false)} >
                <List component="nav">
                    <ListItem button component={Link} to="/" selected>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button component={Link} to="/partners">
                        <ListItemText primary="Partners" />
                    </ListItem>
                    <ListItem button component={Link} to="/faq">
                        <ListItemText primary="FAQ" />
                    </ListItem>
                    <ListItem button component={Link} to="/testimonials">
                        <ListItemText primary="Testimonials" />
                    </ListItem>
                    <ListItem button component={Link} to="/about-me">
                        <ListItemText primary="About Me" />
                    </ListItem>
                    <ListItem>
                        <Button color="secondary" variant="outlined">Schedule a Call</Button>
                    </ListItem>
                </List>
            </Drawer>
        </>
    )
}

export default NavMenu
import React, { useState } from 'react'
import { Link } from "gatsby"
import { AppBar, Toolbar, Container, Tabs, Tab, Button, ButtonGroup, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'

import NavMenu from 'components/NavMenu'

const useStyles = makeStyles(theme => ({
    appbar: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        backgroundColor: `${theme.palette.common.gray}44`
    },

    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    logo: {
        justifySelf: 'center'
    },

    tabList: {
        display: 'none',

        [theme.breakpoints.up('lg')]: {
            display: 'inline-block'
        },

        '& *': {
            textTransform: 'none',
            color: theme.palette.common.white,
            fontWeight: 400,
            textShadow: `2px 2px 3px #000000AA`
        }
    }
}))

const SiteHeader = () => {
    const [activeTab, setActiveTab] = useState(0)

    const handleChangeTab = (e, tab) => {
        setActiveTab(tab);
    };

    const classes = useStyles()
    const theme = useTheme()

    return (
        <AppBar position="fixed" color="transparent" elevation={0} className={classes.appbar}>
            <Container maxWidth="xl">
                <Toolbar className={classes.toolbar}>
                    <img
                        src={require('images/j3-logo-white.png')}
                        className={classes.logo}
                    />
                    <Tabs
                        className={classes.tabList}
                        value={activeTab}
                        onChange={handleChangeTab}
                        indicatorColor="secondary"
                    >
                        <Tab label="Projects" component={Link} to="#projects" />
                        <Tab label="Firm" component={Link} to="#firm" />
                        <Tab label="Specialty" component={Link} to="#specialty" />
                        <Tab label="Contact" component={Link} to="#contact" />
                        <Button color="secondary" variant="outlined">Schedule a Call</Button>
                    </Tabs>
                    <NavMenu display={useMediaQuery(theme.breakpoints.up('lg')) ? 'none' : 'inline-block'} />
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default SiteHeader